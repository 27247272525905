<template>
<div>

    <div style="position:absolute;bottom:0px;height:calc(100%);width:100%;display:flex;justify-content:center;align-items:center;overflow-x:hidden;overflow-y:scroll;">
        
        <div class="row" style="display:flex;justify-content:center;margin-top:-24px;">
          <div class="col-md-8" style="display:flex;justify-content:center;">
            <div class="row" style="displa:flex;justify-content:center">

            
             
              <div class="col-md-12 mb-4" style="display:flex;justify-content:center;">
                <avatar :username="avatarName" :size="90" class="avatar avatar-xlg mr-3"></avatar>
                
              </div>
                  
              
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color:rgba(0,0,0,0.6)">Name *</label>
                    <input  v-model="name" name="contact-name" type="text" placeholder="Name" class="form-control" required="">
                    <div class="invalid-feedback">
                      Please type your name.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                    <input v-model="user.email" name="contact-email" type="email" placeholder="you@coolvines.com" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Password *</label>
                    <input v-model="user.password" name="password" type="password" placeholder="Password" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                    <input v-model="user.pconfirm" name="password" type="password" placeholder="Confirm Password" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Branch Local *</label>
                    <select @change="localChange" class="form-control">
                    <option selected="">Select Local</option>
                    <option value="NW">Newark</option>
                    <option value="JC">Jersey City</option>
                    <option value="HB">Hoboken</option>
                    <svg style="z-index:4;position:absolute;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                    </svg>
                    </select>
                    
                  </div>
                </div>
                <div class="col-md-12" style="display:flex;justify-content:center;align-items:center;margin-top:40px">
                  <div style="position:absolute;height:40px;width:40px;left:0px;display:flex;align-items:center;margin-top:-40px;">
                    <div class="custom-control custom-switch">
                      <input v-model="user.isadmin" type="checkbox" class="custom-control-input" id="customSwitch1">
                      <label class="custom-control-label" for="customSwitch1">Admin</label>
                    </div>
                  </div>
                  
                  <button @click.prevent="createUser2" type="submit" class="btn btn-primary btn-loading" data-loading-text="Sending">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon" data-src="assets/img/icons/theme/code/loading.svg">
                        <title>Icon For Loading</title>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g>
                                <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                            </g>
                            <path d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "></path>
                        </g>
                    </svg>
                                        <span>Create Account</span>
                  </button>
                </div>
              </div>
          
            
        </div>
       </div>
        
    </div> 
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'

export default {
    components: {
      Avatar
    },  
    data() {
      return {
        avatarName: "cool vines",
        local: '',
        name: '',
        user: {
          name: '',
          email: '',
          password: '',
          pconfirm: '',
          local: '',
          isadmin: false,
        }
      }
    },
    watch: {
      name() {
        this.avatarName = this.name;
        this.user.name = this.name;
      },
      
    },
    methods: {
      createUser() {
        console.log('creating user');
      
          axios.post('/api/createuser',  this.user ).then(resp => {
            console.log(resp.data);
          });
      },
      createUser2() {

        if(this.user.name != '' && this.user.email != '' && this.user.password == this.user.pconfirm && this.user.local != '') {
          console.log(this.user);
          axios.post('/api/createuser',  this.user ).then(resp => {
            console.log(resp.data);
          }).then(resp2 => {
            this.$router.push({name: 'admin.users'});
            this.$toasted.success('Created!');
          });
          
          
        } else if(this.user.name == '') {
          this.$toasted.error('Please Include Name')
        } else if(this.user.email == '') {
          this.$toasted.error('Please Include Email')
        } else if(this.user.password != this.user.pconfirm) {
          this.$toasted.error("Passwords Don't Match")
        } else if(this.user.local == '') {
          this.$toasted.error('Please Select Local');
        }
        
      },
      localChange(e) {
        if(e.target.value != 'Select Local') {
          console.log(e.target.value);
          this.user.local = e.target.value;
        } else {
          this.user.local = '';
        }
        
      }
      
    },
}
</script>