<template>
<div>
    <div v-if="!loading" style="position:absolute;bottom:0px;height:calc(100%);width:100%;display:flex;justify-content:center;align-items:center;overflow-x:hidden;overflow-y:scroll;">
        
       <div class="row" style="display:flex;justify-content:center;margin-top:-24px;">
          <div class="col-md-8" style="display:flex;justify-content:center;">
            <div class="row" style="displa:flex;justify-content:center">

            
             
              <div class="col-md-12 mb-4" style="display:flex;justify-content:center;">
                <avatar v-if="user.name" :username="user.name" :size="90" alt="Benjamin Cameron" class="avatar avatar-xlg mr-3"></avatar>
              </div>
                  
              
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color:rgba(0,0,0,0.6)">Name *</label>
                    <input v-model="user.name" name="contact-name" type="text" :placeholder="this.$route.params.email" class="form-control" required="">
                    <div class="invalid-feedback">
                      Please type your name.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                    <input v-model="user.email" name="contact-email" type="email" :placeholder="this.$route.params.email" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Password *</label>
                    <input v-model="user.password" name="password" type="password" placeholder="Password" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                    <input v-model="user.pconfirm" name="password" type="password" placeholder="Confirm Password" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Branch Local *</label>
                    <select v-model="user.local" v-bind:value="user.local" class="form-control">
                    
                    <option value="NW">Newark</option>
                    <option value="JC">Jersey City</option>
                    <option value="HB">Hoboken</option>
                    <svg style="z-index:4;position:absolute;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                    </svg>
                    </select>
                    
                  </div>
                </div>
                <div class="col-md-12" style="display:flex;justify-content:center;align-items:center;margin-top:40px">
                  <div style="position:absolute;height:40px;width:40px;left:0px;display:flex;align-items:center;margin-top:-40px;">
                    <div class="custom-control custom-switch">
                      <input v-model="user.isadmin"  type="checkbox" class="custom-control-input" id="customSwitch1">
                      <label class="custom-control-label" for="customSwitch1">Admin</label>
                    </div>
                  </div>
                  
                  
                  
                  <button v-if="remUser == false" @click.prevent="updateUser" type="submit" class="btn btn-primary btn-loading" data-loading-text="Sending">
                    
                                        <span>Update Account</span>
                  </button>
                  <button v-else @click="delShelf" type="submit" class="btn btn-danger btn-loading" data-loading-text="Sending">
                    
                                        <span>Remove Account</span>
                  </button>
                  
                </div>
                <div class="col-md-7" style="display:flex;justify-content:center;">
                  <span @click="showDelete" class="pt-2" href="" style="color:rgba(0,0,0,0.2);font-size:0.85rem;cursor: pointer;">delete</span>
                </div>
              </div>
          
            
        </div>
       </div>
        
    </div>

    <div v-else-if="loading" style="height:100%;width:100%;display:flex;justify-content:center;align-items:center;">
          <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
          </div>
        </div> 
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
export default {
    components: {
      Avatar,
    },
    data() {
      return {
        loading: false,
        user: {},
        remUser: false,
      }
    },
    created() {
      this.fetchUser();
    },
    methods: {
      fetchUser() {
        this.loading = true;
        console.log('yupp: ' +  this.$route.params.email);
        axios.get('/api/fetchuser?query='+ this.$route.params.email)
          .then(resp => {
            console.log(resp.data.user);
            this.user = resp.data.user;
            this.loading = false;
          });
      },
      updateUser() {
        if(this.user.name != '' && this.user.password!= null && this.user.password == this.user.pconfirm) {
         
          axios.post('/api/updateuser', this.user)
          .then(resp => {
            console.log(resp.data);
            this.$toasted.success('Updated!');
          });
        } else if(this.user.name == '') {
          this.$toasted.error('Please Include Name');
        } else if(this.user.email == '') {
          this.$toasted.error('Please Include Email');
        } else if(this.user.password != this.user.pconfirm) {
          this.$toasted.error("Passwords Don't Match");
        } else if(this.user.password == null) {
          this.$toasted.error('Please Include Password');
        }
        
      },
      delUser() {
        alert('Deleting User');
      },
      showDelete() {
        this.remUser = !this.remUser;
        console.log(this.remUser);
      },
      delShelf() {
        this.loading=true;
        

          if(confirm('Remove User?')) {
            console.log('Deleting user');
       
            console.log(this.item);
            axios.post('/api/user/delete', this.user)
              .then(response => {
                this.loading= false;
                this.$router.push({name: 'admin.users'});
                this.$toasted.show('Successfully Removed!')
                console.log(response.data.success);
              });
          }
       
      }, 
      
    }
}
</script>
<style scoped>

</style>