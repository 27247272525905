<template>
<div>

    <div style="position:absolute;bottom:0px;height:calc(100%);width:100%;display:flex;justify-content:center;overflow-x:hidden;overflow-y:scroll;">
        
        <div class="row" style="display:flex;justify-content:center;">
          <div class="col-md-8" style="">
            <div class="row " style="displa:flex;justify-content:centermargin-top:-24px;">

                
                  
                  <div class="col-md-12 mt-6 mb-4" style="display:flex;justify-content:center;">
                    <!-- <h3 style="color:rgba(0,0,0,0.8);font-weight:300">Creating Shelf</h3> -->
                    <h1 class="h2 mb-3 text-uppercase" style="color:rgba(0,0,0,0.8);letter-spacing: 0.255rem;">Shelf Talker</h1>
                  </div>
                      
                    <div  class="col-md-12">
                      <div class="form-group" style="margin-bottom:0px;padding-bottom:0px;">
                        <label style="color: rgba(0,0,0,0.6)">Shelf Category *</label>
                      </div>
                      <div class="form-group">
                        <select @change="selectedCategory" class="custom-select">
                          <option selected value="">Select Category</option>
                          <option value="wine">Wine</option>
                          <option value="spirits">Spirits</option>
                          <option value="beer">Beer</option>
                          <option value="food">Market</option>
                        </select>
                      
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="https://leap.mediumra.re/assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color:rgba(0,0,0,0.6)">Title *</label>
                        <input v-model="item.title" name="contact-name" type="text"  class="form-control" placeholder="Item Title">
                        
                      </div>
                    </div>
                    
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">ShelfTalker Description</label>
                        <textarea v-model="item.shelf_description" class="form-control" name="contact-message" rows="6" placeholder="ShelfTalker Description" ></textarea> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Description</label>
                       
                        <quill-editor 
                          class="form-control" style="padding-left:0px;padding-right:0px;min-height:270px;max-height:450px;"
                          ref="myQuillEditor"
                         
                           v-model="item.description"
                           :options="editorOption"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Item Remarks</label>
                        <textarea v-model="item.item_remark" class="form-control" name="contact-message" placeholder="Item Remarks" rows="6"></textarea> 
                      </div>
                    </div>
                    <div v-if="item.category == 'wine'" class="col-md-12">
                      <div class="form-group" style="margin-bottom:0px;padding-bottom:0px;">
                        <label style="color: rgba(0,0,0,0.6)">Wine Type</label>
                      </div>
                      <div class="form-group">
                        
                        <select @change="selectedWineType" class="custom-select">
                          <option selected="">Select Type</option>
                          <option value="Red Wine">Red Wine</option>
                          <option value="White Wine">White Wine</option>
                          <option value="Sparkling Wine">Sparkling Wine</option>
                          <option value="Rose Wine">Rose Wine</option>
                          <option value="Orange Wine">Orange Wine</option>
                        
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="https://leap.mediumra.re/assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                        </svg>
                      </div>
                    </div>
                    <div  class="col-md-12">
                      <div class="form-group mt-1">
                        <label class="mb-2" style="color: rgba(0,0,0,0.6)">Farm Practice</label>
                        <div class="row mt-1">
                          <div v-if="item.category == 'wine' || item.category == 'food'" class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_natural" class=" mr-1" type="checkbox">
                              <label>Natural</label>
                            </div>
                          </div>
                          <div v-if="item.category == 'wine' || item.category == 'food'" class="col-4">
                            <div class="mb-3">
                              <input v-model="item.farmpractice_biodynamic" class="mr-1" type="checkbox">
                              <label >Biodynamic</label>
                            </div>
                          </div>
                          <div v-if="item.category == 'wine' || item.category == 'beer' || item.category == 'food'" class="col-4">
                            <div class="mb-3">
                              <input v-model="item.farmpractice_limited" class="mr-1" type="checkbox">
                              <label>Limited</label>
                            </div>
                          </div>
                          <div v-if="item.category != 'beer'" class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_organic" class="mr-1" type="checkbox">
                              <label>Organic</label>
                            </div>
                          </div>
                          <div v-if="item.category == 'wine' || item.category == 'food'" class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_kosher" class="mr-1" type="checkbox">
                              <label>Kosher</label>
                            </div>
                          </div>
                          <div v-if="item.category == 'wine' || item.category == 'food'" class="col-4">
                            <div class=" mb-3" style="">
                              <input v-model="item.farmpractice_sustainable" class="mr-1" type="checkbox" style="">
                              <label>Sustainable</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Producer</label>
                        <input v-model="item.producer" name="contact-name" type="text" class="form-control" placeholder="Item Producer"> 
                      </div>
                    </div>
                    <div v-if="this.item.category == 'food'" class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Food Category</label>
                        <input v-model="item.foodcategory" name="contact-name" type="text" class="form-control" placeholder="Food Category"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Country</label>
                        <input v-model="item.country" name="contact-name" type="text" class="form-control" placeholder="Item Country"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Region</label>
                        <input v-model="item.region" name="contact-name" type="text" class="form-control" placeholder="Item Region"> 
                      </div>
                    </div>
                    <div v-if="this.item.category == 'food'" class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Subregion</label>
                        <input v-model="item.subregion" name="contact-name" type="text" class="form-control" placeholder="Item Subregion"> 
                      </div>
                    </div>
                    <div v-if="item.category == 'wine'" class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Grape</label>
                        <input v-model="item.grape" name="contact-name" type="text" class="form-control" placeholder="Item Grape"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Vintage</label>
                        <input v-model="item.vintage" name="contact-name" type="text" class="form-control" placeholder="Item Vintage"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Style</label>
                        <input v-model="item.style" name="contact-name" type="text" class="form-control" placeholder="Item Style"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Body</label>
                        <input v-model="item.body" name="contact-name" type="text"  class="form-control" placeholder="Item Body"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Size</label>
                        <input v-model="item.size" name="contact-name" type="text"  class="form-control" placeholder="Item Size"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Price *</label>
                        <input v-model="item.price" name="contact-name" type="text" class="form-control" placeholder="Item Price"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Powerhouse Link</label> <button v-if="!item.powerhouse_link" @click="pNoneClick()" class="btn" style="margin-top:-5px;margin-left:0px;paddingleft:0px;"><i class="uil uil-plus-circle" style="font-size:0.85rem;"></i></button>
                        <input v-model="item.powerhouse_link" name="contact-name" type="text" class="form-control" placeholder="Link to Powerhouse Reference"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Newark Link</label><button v-if="!item.newark_link" @click="nNoneClick()" class="btn" style="margin-top:-5px;margin-left:0px;paddingleft:0px;"><i class="uil uil-plus-circle" style="font-size:0.85rem;"></i></button>
                        <input v-model="item.newark_link" name="contact-name" type="text" class="form-control" placeholder="Link to Newark Reference"> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Hoboken Link</label><button v-if="!item.hoboken_link" @click="hNoneClick()" class="btn" style="margin-top:-5px;margin-left:0px;paddingleft:0px;"><i class="uil uil-plus-circle" style="font-size:0.85rem;"></i></button>
                        <input v-model="item.hoboken_link" name="contact-name" type="text" class="form-control" placeholder="Link to Hoboken Reference"> 
                      </div>
                    </div>
                    
                    <div class="col-md-12" style="display:flex;justify-content:center;">
                      
                      <button v-if="loading == false" @click="createShelf()" type="submit" class="btn btn-primary btn-loading mt-4 m-6" data-loading-text="Sending">
                        
                                            <span >Create Shelf</span>
                                            
                      </button>
                      <span v-else class="mt-4 m-6">
                                              <div class="spinner-border" role="status">
                                              <span class="sr-only">Loading...</span>
                                              </div>
                                            </span>

                      
                    </div>
                  </div>
          
            
        </div>
       </div>
        
    </div> 
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    components: {
      Avatar, quillEditor
    },  
    data() {
      return {
        avatarName: "cool vines",
        local: '',
        loading: false,
        name: '',
        item: {
          category: '',
          title: '',
          shelf_description: '',
          description: '',
          item_remark: '',
          wine_type: '',
          farmpractice_natural: '',
          farmpractice_biodynamic: '',
          farmpractice_limited: '',
          farmpractice_organic: '',
          farmpractice_kosher: '',
          farmpractice_sustainable: '',
          producer: '',
          foodcategory: '',
          country: '',
          region: '',
          subregion: '',
          grape: '',
          vintage: '',
          style: '',
          body: '',
          size: '',
          price: '',
          powerhouse_link: '',
          newark_link: '',
          hoboken_link: '',

        },
        user: {
          name: '',
          email: '',
          password: '',
          pconfirm: '',
          local: '',
          isadmin: false,
        },
        editorOption: {
          theme: 'bubble',
          
          modules: {
            toolbar: [
               ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'size': [ 'small', false, 'large', 'huge' ] }],
              
              [{ 'align': [] }],
         
              ['clean']
            ]
          }
        },
      }
    },
    methods: {
      createShelf() {
        console.log(this.item);
        if(this.item.title != '' && this.item.category != '' && this.item.price != '' && (this.item.hoboken_link + this.item.newark_link + this.item.powerhouse_link) != '') {
          this.loading = true;
            this.$store.state.page = 1;
            this.$store.state.posCategory = 'created';
            this.$store.state.posItem = '';
            this.$store.state.query = '',
            this.$store.state.displayResults = [];
          axios.post('/api/admin/createshelf', this.item).then(resp=> {
            console.log(resp);
            this.loading = false;
          }).then(resp2 => {
            if(this.item.category == 'wine') {
              this.$router.push({name: 'admin.items.wine'});
              this.$toasted.success('Added!');
            } else if(this.item.category == 'spirits') {
              this.$router.push({name: 'admin.items.spirits'});
              this.$toasted.success('Added!');
            } else if(this.item.category == 'beer') {
              this.$router.push({name: 'admin.items.beer'});
              this.$toasted.success('Added!');
            } else if(this.item.category == 'food') {
              this.$router.push({name: 'admin.items.food'});
              this.$toasted.success('Added!');
            }
          });
        } else if(this.item.category == '') {
            this.$toasted.error('Item Category');
        } else if(this.item.title == '') {
            this.$toasted.error('Item Title');
        } else if(this.item.price == '') {
            this.$toasted.error('Item Price');
        } else if(this.item.hoboken_link + this.item.newark_link + this.item.powerhouse_link == '') {
            this.$toasted.error("Inlude Link");
        }
        
      },
      createUser() {
        console.log('creating user');
      
          axios.post('/api/createuser',  this.user ).then(resp => {
            console.log(resp.data);
          });
      },
      createUser2() {

        if(this.user.name != '' && this.user.email != '' && this.user.password == this.user.pconfirm && this.user.local != '') {
          console.log(this.user);
          axios.post('/api/createuser',  this.user ).then(resp => {
            console.log(resp.data);
          }).then(resp2 => {
            this.$router.push({name: 'admin.users'});
            this.$toasted.success('Created!');
          });
          
          
        } else if(this.user.name == '') {
          this.$toasted.error('Please Include Name')
        } else if(this.user.email == '') {
          this.$toasted.error('Please Include Email')
        } else if(this.user.password != this.user.pconfirm) {
          this.$toasted.error("Passwords Don't Match")
        } else if(this.user.local == '') {
          this.$toasted.error('Please Select Local');
        }
        
      },
      localChange(e) {
        if(e.target.value != 'Select Local') {
          console.log(e.target.value);
          this.user.local = e.target.value;
        } else {
          this.user.local = '';
        }
        
      },
      selectedCategory(event) {
        if(event.target.value !== null || event.target.value != '') {
          this.item.category = event.target.value;
          console.log(event.target.value);
        }
        
       
      },
      selectedWineType(event) {
        if(event.target.value !== null || event.target.value != '') {
          this.item.wine_type = event.target.value;
          console.log(event.target.value);
        }
        
       
      },
      pNoneClick() {
        this.item.powerhouse_link = 'none';
      },
      nNoneClick() {
        this.item.newark_link = 'none';
      },
      hNoneClick() {
        this.item.hoboken_link = 'none';
      },
      
    },
}
</script>